/**
 * Styles for the various stylistic variations of the actual names themselves.
 */

main.newlines {
	line-height: 0.8em;
}

main.newlines span {
	margin-bottom: 0.5rem;
}

main.newlines.centre span {
	text-align: center;
}

main.newlines span:last-child {
	margin-bottom: 0;
}

main.newlines span {
	display: block;
}

main span.fancy {
	font-style: italic;
}

main.uppercase {
	text-transform: uppercase;
}

main.lowercase {
	text-transform: lowercase;
}
