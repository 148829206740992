button {
	background: rgba(0, 0, 0, 0.1);
	border: 0;
	border-radius: 50px;
	cursor: pointer;
	display: block;
	height: 80px;
	left: auto;
	width: 80px;
	position: sticky;
	top: 100%;
}

button#refresh {
	right: 0;
}

button#download {
	/* right: 100px; */
	left: 20px;
	top: 20px;
	height: 50px;
	width: 50px;
}

button#download svg {
	margin-left: -1px;
	height: 34px;
}

button#open-link {
	left: auto;
	top: 20px;
	right: 20px;
}

button svg {
	color: rgb(141, 52, 242);
	height: 60px;
	margin-left: 1px;
	margin-top: 4px;
	display: inline-block;
}

button#refresh[disabled] svg {
	animation: spinning 1s infinite linear;
}

@keyframes spinning {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}

footer {
	display: flex;
	position: sticky;
	top: 100%;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
	padding: 0 1em 1em 1em;
	align-items: flex-end;
}

footer #credits {
	display: inline;
	color: rgba(0, 0, 0, 0.5);
	font-size: 0.75em;
	flex-grow: 1;
	padding-right: 1em;
}

@media only screen and (min-width: 700px) {
	footer #credits {
		font-size: 1em;
	}
}

footer #watermark {
	font-size: 2.5vh;
	display: block;
	opacity: 0.5;
}

footer a,
footer a:visited {
	color: rgb(141, 52, 242);
}
