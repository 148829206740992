.bg1 {
	background-color: #fc8181;
}
.bg1,
.bg1 a,
.bg1 a:visited,
.bg1 button svg {
	color: #fff;
}

.bg2 {
	background-color: #ff9300;
}
.bg2,
.bg2 a,
.bg2 a:visited,
.bg2 button svg {
	color: #fff;
}

.bg3 {
	background-color: #fefcbf;
}
.bg3,
.bg3 a,
.bg3 a:visited,
.bg3 button svg {
	color: #e53e3e;
}

.bg4 {
	background-color: #c6f6d5;
}
.bg4,
.bg4 a,
.bg4 a:visited,
.bg4 button svg {
	color: #2f855a;
}

.bg5 {
	background-color: #b2f5ea;
}
.bg5,
.bg5 a,
.bg5 a:visited,
.bg5 button svg {
	color: rgba(0, 0, 0, 0.6);
}

.bg6 {
	background-color: #bee3f8;
}
.bg6,
.bg6 a,
.bg6 a:visited,
.bg6 button svg {
	color: #2a4365;
}

.bg7 {
	background-color: #4c51bf;
}
.bg7,
.bg7 button svg,
.bg7 footer #credits,
.bg7 button#refresh svg {
	color: #fff;
}
.bg7 a,
.bg7 a:visited {
	color: #ebf4ff;
}

.bg8 {
	background-color: #e9d8fd;
}
.bg8,
.bg8 a,
.bg8 a:visited,
.bg8 button svg {
	color: #553c9a;
}

.bg9 {
	background-color: #fed7e2;
}
.bg9,
.bg9 a,
.bg9 a:visited,
.bg9 button svg {
	color: #d53f8c;
}

.bg11 {
	background-color: #0094de;
}
.bg11,
.bg11 a,
.bg11 a:visited,
.bg11 footer #credits,
.bg11 button svg {
	color: #bdfdff;
}

.bg12 {
	background-color: #febfd5;
}
.bg12,
.bg12 a,
.bg12 a:visited,
.bg12 button svg {
	color: #e53e3e;
}

.bg13 {
	background-color: #f5fff8;
}
.bg13,
.bg13 a,
.bg13 a:visited,
.bg13 button svg {
	color: #2f855a;
}

.bg14 {
	background-color: #f0fdfb;
}
.bg14,
.bg14 a,
.bg14 a:visited,
.bg14 button svg {
	color: rgba(0, 0, 0, 0.6);
}

.bg10 {
	background-color: #f2f9fe;
}
.bg10,
.bg10 a,
.bg10 a:visited,
.bg10 button svg {
	color: #2a4365;
}
